<template>
<div>
  <v-menu
    ref="menu1"
    v-model="toggle_menu_date"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    :nudge-left="nudgeLeft"
    max-width="290px"
    min-width="200px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        label
        class="pa-5 input-date"
        :style="chipStyle"
        v-bind="$attrs"
        v-on="on"
      >
        <div class="d-flex justify-space-between fill-width">
          <div>
            <v-icon left size="22" color="icono">mdi-calendar</v-icon>
            {{selected_date | smartDate}}
          </div>
          <v-icon class="float-right" small v-text="toggle_menu_date ? 'mdi-menu-up' : 'mdi-menu-down' "></v-icon>
        </div>
      </v-chip>
    </template>
    <v-date-picker
      v-model="selected_date"
      no-title
      :max="max"
      :min="min"
      :picker-date="pickerInitial"
    >
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        @click="toggle_menu_date = false"
      >
        Aceptar
      </v-btn>
    </v-date-picker>
  </v-menu>
  <template v-if="!hiddenMsg">
    <div v-show="error" class="v-messages theme--light error--text d-block mt-2" role="alert">
      <div class="v-messages__wrapper">
        <div
          class="v-messages__message"
          v-text="error"
        />
        </div>
      </div>
  </template>
</div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'InputFecha',
  props:{
    value: String,
    min: String,
    max: String,
    pickerInitial: String,
    widthInput: {
      type: String,
      default: 'auto',
    },
    error: String,
    hiddenMsg: {
      type: Boolean,
      default: false,
    },
    nudgeLeft: {
      type: Number,
      default: 100,
    },
  },
  data(){
    return {
      toggle_menu_date: false,
      selected_date: this.value,
    }
  },
  watch:{
    value(val, oldVal){
      if(val === oldVal) return

      this.selected_date = val;
    },
    selected_date (val, oldVal) {
      if(val === oldVal) return

      const date_text = val != '' ? moment(val).format('YYYY-MM-DD') : val
      this.$emit('input', date_text);
    }
  },
  methods:{
    resetDate() {
      this.selected_date = '';
    }
  },
  computed: {
    chipStyle () {
      return {
        width: this.widthInput,
      }
    },
  }
}
</script>
<style>
.input-date .v-chip__content {
  width: 100% !important;
}
</style>
